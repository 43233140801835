.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}


.input-container:focus-within label {
  transform: translate(0, 8px) scale(0.8);
}

.input-container label {
  position: absolute;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #808080;
  font-size: 15px;
  line-height: 1;
  left: 16px;
  font-family: poppins;
}

.input-container input,
.input-container select,
.input-container textarea {
  height: 60px;
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container select{
  padding: 0 16px;

}
.input-container textarea {
  height: 100px;
}

.input-container input:focus,
.input-container textarea:focus {
  box-shadow: 0 0 0 1px #1a1a1a;

}

button {
  border: 1px solid orange;
  background: orange;
  color: white;
  text-decoration: none;
  padding: 12px 30px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

button:hover {
  transform: scale(1.08);
  transition: all 0.2s ease-in-out;
  z-index: 99;
}

.non-floating-inputs{} 