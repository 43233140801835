.product-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* background-color: #F1D196; */
}
/* .product-container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  opacity: 0;
  transition: 0.5s ease all;
} */
.product-main-container .product-container img {
  transition: 0.5s ease all;
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease all;
}
.product-main-container {
  transition: 0.5s ease all;
}
/* .product-main-container:hover .product-container img {
  transform: scale(1.07);
  transition: 0.5s ease all;
} */
/* .product-container:hover img {
  transform: scale(1.07);
  transition: 0.5s ease all;
} */
.product-main-container h4 {
  color: #121212;
  font-size: 20px;
  border-bottom: 1px solid transparent;
  font-family: Mariposa-sans;
}
.product-main-container h2 {
  color: orange;
  font-weight: 500;
  font-size: 11px;
  font-family: poppins;
}

.product-main-container p {
  color: #720606;
  font-weight: bolder;
  font-size: 18px;
  font-family: poppins;
}

.product-main-container .buy-now, .about-us-btn {
  border: 1px solid orange;
  background: orange;
  color: white !important;
  text-decoration: none;
  padding: 12px 30px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}
.product-container-img {
  background-color: rgb(235, 226, 226);
  width: 100%;
  height: 200px;
}
@media screen and (min-width: 1025px) {
  /* .product-main-container .product-container img:last-child {
    display: none;
    transition: 0.5s ease all;
  }
  .product-main-container:hover .product-container img:last-child {
    display: block;
    transition: 0.5s ease all;
  }
  .product-main-container:hover .product-container img:first-child {
    display: none;
    transition: 0.5s ease all;
  } */
  .product-main-container:hover h4 {
    border-bottom: 1px solid #121212;
    transition: 0.5s ease all;
  }
}

@media screen and (max-width: 1024px) {
  .hoverableImage {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .product-container img {
    margin-bottom: 10px;
  }
  .hoverableImage {
    display: none;
  }
  .product-main-container h4 {
    font-size: 18px;
  }

  .product-main-container p {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.product-main-container {
  border: 1px solid rgb(231 229 229);
  padding: 20px 10px;
}

.product-main-container p {
  color: #121212bf;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  border-radius: 6px;
  font-weight: 500;
}

.product-main-container p span {
  color: #323131bf;
  font-weight: 400;
}
.priceSpan {
  text-decoration: line-through;
}

.product-main-container a {
  color: #121212bf;
  font-weight: 400;
}
.likeSection svg {
  font-size: 18px !important;
  cursor: pointer;
}
.fillpath path {
  fill: orange;
}
